import { faRectangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { MatchCard } from "../../api/schema";
import { formatShirtNr } from "../../utils";
import { getSideClass } from "./LiveFeed";
import styles from './PlayerCard.module.scss';
import TimelineMinutes from "./TimelineMinutes";

type props = {
  event: MatchCard,
  hidePlayers: boolean
}

export default function PlayerCard ({event, hidePlayers} : props) {

  const T = useTranslations('cmresults');

  const cardAttributes = (color: string) => {
    if (color === 'red') {
      return {
        color: 'red',
        name: T('Red card')
      }
    }
    if (color === 'yellow') {
      return {
        color: 'yellow',
        name: T('Yellow card')
      }
    }
    if (color === 'green') {
      return {
        color: 'green',
        name: T('Green card')
      }
    }
    if (color === 'blue') {
      return {
        color: 'blue',
        name: T('Blue card')
      }
    }

    return {
      color: 'rgb(var(--clor-gray-700))',
      name: T('Unknown card')
    }
  }

  return <div className={classNames(styles.card_container, getSideClass(event.side), event.side === 'away' && styles.away)}>
    <div className={styles.card_inner_container}>
      <div className={styles.card_info}>
        <div className={styles.card_type}>
          <span>{cardAttributes(event.color).name}</span>
          <FontAwesomeIcon icon={faRectangle} className={classNames(styles.card_clr, styles[cardAttributes(event.color).color])} />
        </div>
        { event.playerName && <div className={styles.player}>
          <span className={styles.name}>{!hidePlayers && event.playerName}</span>
          {event.playerNr && <span className={styles.shirt_nr}>#{formatShirtNr(event.playerNr)}</span>}
        </div>}
      </div>
    </div>
    <div>
      <TimelineMinutes time={event.displayRelativeTime||event.relativeTime} side={event.side} />
    </div>
    <div></div>
  </div>
}