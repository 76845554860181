import { faArrowRight, faClock, faStopwatch, faTrophy, faUsersClass } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faCircle, faStarShooting } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { match } from "assert";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { useLink } from "granular";
import { useContext, useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { Match, useDivision, useMatch } from "../../api/schema"
import { LoadingPath, useCurrentTournament } from "../../App";
import { getColorString, useColors } from "../../colors";
import Flag from "../Flag/Flag";
import GameFieldIcon from "../GameFieldIcon/GameFieldIcon";
import LinkCardLoading from "../LinkCardLoading/LinkCardLoading";
import StopWatch from "../LiveFeed/StopWatch";
import LiveSymbols from "../LiveSymbols/LiveSymbols";
import MiddleEllipsisSpan from "../MiddleEllipsisSpan/MIddleEllipsisSpan";
import Score from "../Score/Score";
import styles from './Game.module.scss';


GamePlayoffTargets.fragment = Match.fragment({
  nextMatchWinner: {match:{}},
  nextMatchLoser: {match:{}},
})
GameStageTargets.fragment = Match.fragment({
  division: {
    ___on_Conference: {
      table: {
        rows: [{team: { }, targetStage: {}}]
      }
    }
    ,
    stage: {
      nextStages: [{}]
    }
  }
})
TeamStageTarget.fragment = Match.fragment({
  division: {
    ___on_Conference: {
      table: {
        rows: [{team: { }, targetStage: {}}]
      }
    }
    ,
    stage: {
      nextStages: [{}]
    }
  }
})
Game.Fragment = Match.fragment({
  home: {
    team: {
      club: {
        nation: {}
      }
    }
  },
  away: {
    team: {
      club: {
        nation: {}
      }
    }
  },
  roundName: {},
  video: {},
  result: {},
  division: {
    stage: {},
    category: {}
  },
  stage: {},
  arena: {},
})

type props = {
  data: Match<typeof Game.Fragment>,
  knownDivision?: boolean,
  onlyPrint?: boolean,
}

export const gameLiveStatusClassName = ({finished, live, externalVideoLink} : {
  finished: boolean,
  live: boolean,
  externalVideoLink: boolean
}) => {
  if(live && !finished) {
    return externalVideoLink
      ? styles.live_streaming_now
      : styles.live_reporting_now
  }
  if(!live && !finished && externalVideoLink) {
    return styles.live_streaming_later
  }
  return;
}


export default function Game ({data, knownDivision, onlyPrint} : props) {

  const T = useTranslations("cmresults");
  const t = useCurrentTournament();
  const sport = t.subcup.sport.type;
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const loadingPath = useContext(LoadingPath);
  const loadingThisPath = loadingPath === `/match/${data.id}`;

  

  const stageGroupRoundName = data.division.__typename === 'Playoff' 
    ? `${data.division.stage.name} ${T.select(data.division.name) != data.division.stage.name ? T.select(data.division.name) : ''} ${T.select(data.roundName.name) ? ` - ${T.select(data.roundName.name)}` : ''}`
    : `${data.division.stage.name} - ${T.select(data.division.name)}`


  const hasStageTargets = data.division.__typename=="Conference" && data.division.size === 2;
  
  return <Link to={`/match/${data.id}`} className={
      classNames(styles.game_container, gameLiveStatusClassName({
        finished: data.finished,
        live: data.live,
        externalVideoLink: !!data.video && !!data.video.externalLink
      }),
      onlyPrint && "only-print")
    }>

    <LinkCardLoading show={loadingThisPath}/>

    {(data.live || data.finished) && <div className={styles.match_clock}>
        {data.finished && !data.division.category.hideMatchResults && data.result.penalties && <div>
          <FontAwesomeIcon icon={faStarShooting} /> {T('Penalties')}
          </div>}
        <div>{data.finished 
          ? <>
            
            <FontAwesomeIcon icon={faCheck} /> {!data.division.category.hideMatchResults && 
                                                (data.result.walkover ? T('Walkover') : formatDate(data.start, 'HH:mm'))}
          </> 
          : (data.live && !onlyPrint)
            ? <Suspense fallback={<></>}><MatchClock match={data}/></Suspense>
            : <><FontAwesomeIcon icon={faClock} /> {formatDate(data.start, 'HH:mm')}</> 
        }</div>
      </div>}

    
    {!(data.live || data.finished) && hasStageTargets && !onlyPrint && <Suspense><GameStageTargets match={data}/></Suspense>}
    {!(data.live || data.finished) && data.division.__typename == "Playoff" && !onlyPrint && <Suspense><GamePlayoffTargets match={data}/></Suspense>}

    <div className={styles.game_content}>
      {/* TEAMS */}
      <div className={styles.teams}>
        <MatchCardTeam 
          showStageTargets={data.finished && hasStageTargets && !onlyPrint}
          match={data}
          everStarted={
            data.live || data.finished
          }
          name={T.select(data.home.name)}
          code={data?.home?.team?.club.nation.code}
          teamId={data.home.id}
          leader={!data.division.category.hideMatchResults && data.result.winner === "home"}
          nationName={T.select(data?.home?.team?.club.nation.name)}
          teamLogoUrl={data.home.team?.clubLogoUrl}
        />
        <MatchCardTeam 
          showStageTargets={data.finished && hasStageTargets && !onlyPrint}
          match={data}
          everStarted={
            data.live || data.finished
          }
          name={T.select(data.away.name)}
          code={data?.away?.team?.club.nation.code}
          teamId={data.away.id}
          leader={!data.division.category.hideMatchResults && data.result.winner === "away"}
          nationName={T.select(data?.away?.team?.club.nation.name)}
          teamLogoUrl={data.away.team?.clubLogoUrl}
        />
      </div>
      {/* / TEAMS */}
      {/* SCORE - TIME */}
      <div className={styles.time_score}>
        {
          (data.live || data.finished ) && !data.division.category.hideMatchResults
          ? <Score result={data.result} />
          : <span className={styles.time_container}>
            <span className="visually_hidden">{T('Game starts at')}</span>
            <span className={styles.start_time}>
              {data.arena && data.arena.id !== 0 
                ? <>
                  <span className={styles.h}>{formatDate(data.start, 'HH').substring(0,1)}</span>
                  <span className={styles.h}>{formatDate(data.start, 'HH').substring(1,2)}</span>:
                  <span className={styles.m}>{formatDate(data.start, 'mm').substring(0,1)}</span>
                  <span className={styles.m}>{formatDate(data.start, 'mm').substring(1,2)}</span>
                </>
                : <>
                  <span className={styles.h}>-</span>
                  <span className={styles.h}>-</span>:
                  <span className={styles.m}>-</span>
                  <span className={styles.m}>-</span>
                </>
              }
            </span>
          </span>
        }
      </div>
      {/* / SCORE - TIME */}
    </div>
    <div className={styles.extra_info}>
      <div className={styles.row}>
        <span>
          <FontAwesomeIcon icon={faUsersClass} />
          <MiddleEllipsisSpan inline text={data.division.category.name} />
        </span>
        <span>
          <FontAwesomeIcon icon={faTrophy}/>
          <MiddleEllipsisSpan inline text={stageGroupRoundName} />
        </span>
      </div>

      <div className={styles.row}>
        <span>
        {sport 
          ? <div className={styles.field}>
              <GameFieldIcon icon={sport} />
              <MiddleEllipsisSpan inline className={styles.arena_name} text={data.arena && data.arena.id !== 0 
                ? data.arena.completeName 
                : T('Not scheduled')}
              />
          </div>
          : <div className={classNames(styles.field, styles.warning)}>
            <strong>
              <MiddleEllipsisSpan inline text={T('Not scheduled')} />
            </strong>
          </div>
        }
        </span>
        <span>
          <div className={styles.liveContainer}>
            <LiveSymbols 
              video={data.video} 
              live={data.live} 
              finished={data.finished} 
              start={data.start}
            />
          </div>
        </span>
      </div>
    </div>
  </Link>
}

function MatchClock({match} : {match: Match}){

  // Tick tock
  const [timer, setTimer] = useState(0);

  const liveStart = useLink(match.liveStart,{});

  useEffect(() => {
    let interval = null as any;

    
    interval = setInterval(() => {
      setTimer(Date.now() - liveStart.start);
    }, 1000)
    
    return () => clearInterval(interval)
  }, [])


  const toSpans = (_number: number) => {
    const number = _number < 10
      ? '0' + _number
      : '' + _number
    return number.split('').map(int => <span key={v4()}>{int}</span>)

  }

  return <>
      <FontAwesomeIcon icon={faStopwatch} /> <span className={styles.clock_timer}>
      <span>{toSpans(Math.round(timer/60000))}</span>:<span>{toSpans(Math.round(timer/1000) % 60)}</span>
    </span>
    </>
}

function GamePlayoffTargets({match : _match}: {match: Match<{division:{}}>}) {

  const match = useLink(_match, {
      nextMatchLoser:{
        match:{
          division:{},
          roundName: {}
      }},
      nextMatchWinner:{
        match:{
          division:{},
          roundName: {}
      }},
  });

  const T = useTranslations("cmresults");

  const _colors = useColors();

  const winnerTo = match.nextMatchWinner && (match.nextMatchWinner.match.division.id === _match.division.id ? T.select(match.nextMatchWinner.match.roundName.name) : T.select(match.nextMatchWinner.match.division.name));
  const loserTo = match.nextMatchLoser && (match.nextMatchLoser.match.division.id === _match.division.id ? T.select(match.nextMatchLoser.match.roundName.name) :T.select(match.nextMatchLoser.match.division.name));

  return (match.nextMatchWinner && winnerTo && <div className={styles.target_stages}>
    <Link className={styles.playoff_target_link} to={`/match/${match.nextMatchWinner.match.id}`}>
      <div className={styles.target_stage} style={{'--clr-bg' : `radial-gradient(rgb(var(--clr-gray-600)), rgb(var(--clr-gray-300)))`} as any}>
        {T('Win. to X', winnerTo)}
      </div>
    </Link>
    {match.nextMatchLoser && loserTo && <Link className={styles.playoff_target_link} to={`/match/${match.nextMatchLoser.match.id}`}>
      <div className={styles.target_stage}  style={{'--clr-bg' : `radial-gradient(rgb(var(--clr-gray-600)), rgb(var(--clr-gray-300)))`} as any}>
        {T('Lsr. to X', loserTo)}
      </div>
    </Link>}
 </div>) || <></>
}

function GameStageTargets({match}: {match: Match}) {

  const div = useLink(match.division, {
    ___on_Conference: {
      table: {
        rows: [{team: { }, targetStage: {}}]
      }
    }
    ,
    stage: {
      nextStages: [{}]
    }
  });

  const T = useTranslations("cmresults");

  const _colors = useColors();
  const colors = [_colors.primary,..._colors.complements];

  //const div = match.division;

  const targetColor = (winner: boolean) => {
    const targetStage = div.__typename === "Conference" && div.table?.rows[winner?0:1].targetStage;
    return targetStage && div.stage.nextStages && getColorString(colors[div.stage.nextStages.findIndex(s => s.href === targetStage.href)]);
  }

  const winnerColor = targetColor(true);
  const loserColor = targetColor(false);
  
  return (div.__typename === "Conference" && <div className={styles.target_stages}>{winnerColor && <div className={styles.target_stage}  style={{
      '--clr-bg' : `radial-gradient(${winnerColor}, rgb(var(--clr-gray-500)))`
    } as any
  } > {T('Win. to X', div.table?.rows[0].targetStage?.name)}
 </div>}
 {loserColor && <div className={styles.target_stage}  style={{
      '--clr-bg' : `radial-gradient(${loserColor}, rgb(var(--clr-gray-500)))`
    } as any
  } >  {T('Lsr. to X', div.table?.rows[1].targetStage?.name)}
 </div>}</div>) || <></>
}



type teamProps = {
  everStarted?: boolean,
  name: string,
  code?: string,
  teamId: number,
  leader: boolean | undefined,
  showStageTargets?: boolean,
  match: Match<typeof Game.Fragment>,
  nationName: string | undefined,
  teamLogoUrl?: string
}


function MatchCardTeam ({everStarted, name, code, teamId, leader, showStageTargets, match, nationName: _nationName, teamLogoUrl} : teamProps) {

  const nationName = _nationName ? _nationName : '';

  return <div className={classNames(
    styles.team, leader && styles.bold)
    }>
    {
      teamLogoUrl
       ? <div className={styles.team_logo_container}>
        <img src={teamLogoUrl} alt={name} className={styles.team_logo} />
       </div>
       : code 
        ? <Flag code={code} teamId={teamId} alt={nationName} />
        : <></>
    } 
    <MiddleEllipsisSpan inline className={styles.team_name} text={name}/>
    {showStageTargets && <Suspense><TeamStageTarget teamId={teamId} match={match}/></Suspense>}
  </div>
}

function TeamStageTarget({teamId, match} : {teamId:number, match: Match}){
  const div = useLink(match.division, {
    ___on_Conference: {
      table: {
        rows: [{team: { }, targetStage: {}}]
      }
    }
    ,
    stage: {
      nextStages: [{}]
    }
  });

  const _colors = useColors();
  const colors = [_colors.primary,..._colors.complements];
 // const div = match.division;
  
  const targetStage = div.__typename === "Conference" && div.table?.rows.find(r => r.team?.id === teamId)?.targetStage;
  const targetColor  = targetStage && div.stage.nextStages && div.stage.nextStages.length > 0 && getColorString(colors[div.stage.nextStages.findIndex(s => s.href === targetStage.href)]);

  return  <>{targetColor && <span><FontAwesomeIcon icon={faArrowRight} color={targetColor} />
        <span> </span>
        <FontAwesomeIcon icon={faCircle} color={targetColor} /></span>}</>
}