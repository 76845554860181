import { useTranslations } from "cupman-utils";
import { EventStatistics$GoalkeeperStat } from "../../api/schema";
import ContentGrayContainer from "../ContentGrayContainer";
import classNames from "classnames";
import { formatShirtNr } from "../../utils";
import { PlayerName } from "./PlayerName";
import styles from './TeamMembers.module.scss';
import React from "react";

export default function GoalkeepersTable ({players, isHome, colors, className, settings} : {
  players: EventStatistics$GoalkeeperStat[],
  className?: string,
  isHome: boolean,
  colors: {
    primary: string,
    secondary: string
  }, 
  settings: {
    hidePlayers: boolean
  }
}) {

  const T = useTranslations('cmresults')



  const percent = (n: number) => n ? Math.round(100 * n) : 0;

  function compareGoalkeepers(a: EventStatistics$GoalkeeperStat, b: EventStatistics$GoalkeeperStat) {
    return b.saves - a.saves;
  }

  return isHome 
    ? <ContentGrayContainer className={className}>
        <div className={classNames(styles.goolies_grid)}>
          <div className={styles.player_header}>{T('Nr. / Namn')}</div>
          <div className={classNames(styles.player_header, styles.text_center)}>{T('S%')}</div>
          <div className={classNames(styles.player_header, styles.text_center)}>{T('S')}</div>
          {[...players].sort(compareGoalkeepers).map((player, i, arr) => <React.Fragment key={i}>
            <div className={styles.the_player}>
              <span className={styles.player_number} style={{background: colors.primary, color: colors.secondary}}>
                <span>{formatShirtNr(player.shirtNr)}</span> 
              </span>
              {!!player.name && !settings.hidePlayers && <PlayerName name={player.name} />}
            </div>
            <div className={classNames(styles.text_center, styles.player_stat_unit)}>{percent(player.saves / (player.saves + player.lost))}%</div>
            <div className={classNames(styles.text_center, styles.player_stat_unit)}>{T('X av Y', player.saves, player.saves + player.lost)}</div>
            {i+1 < arr.length && <div 
              className={styles.grid_devider} 
              style={{'--gridColumn' : `1 / span 3`} as any}
            ></div>}
          </React.Fragment>)}
        </div>
      </ContentGrayContainer>
    : <ContentGrayContainer className={className}>
        <div className={classNames(styles.goolies_grid, styles.reverted)}>
          <div className={classNames(styles.player_header, styles.text_center)}>{T('S')}</div>
          <div className={classNames(styles.player_header, styles.text_center)}>{T('S%')}</div>
          <div className={classNames(styles.player_header, styles.text_right)}>{T('Namn / Nr')}</div>
          {[...players].sort(compareGoalkeepers).map((player, i, arr) => <React.Fragment key={i}>
            <div className={classNames(styles.text_center, styles.player_stat_unit)}>{T('X av Y', player.saves, player.saves + player.lost)}</div>
            <div className={classNames(styles.text_center, styles.player_stat_unit)}>{percent(player.saves / (player.saves + player.lost))}%</div>
            <div className={classNames(styles.the_player, styles.text_right)}>
              <span className={styles.player_number} style={{background: colors.primary, color: colors.secondary}}>
                <span>{formatShirtNr(player.shirtNr)}</span> 
              </span>
              {!!player.name &&  !settings.hidePlayers && <PlayerName name={player.name} />}
            </div>
            {i+1 < arr.length && <div 
              className={styles.grid_devider} 
              style={{'--gridColumn' : `1 / span 3`} as any}
            ></div>}
          </React.Fragment>)}
        </div>
      </ContentGrayContainer>
}