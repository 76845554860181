import { useTranslations } from "cupman-utils"
import { EventStatistics$PlayerStat } from "../../api/schema"
import ContentGrayContainer from "../ContentGrayContainer"
import classNames from "classnames"
import styles from './TeamMembers.module.scss';
import { PlayerName } from "./PlayerName";
import { formatShirtNr } from "../../utils";
import React from "react";

export default function OutfieldPlayersTable ({players, isHome, colors, settings, className, teamName} : {
  players: EventStatistics$PlayerStat<{}>[],
  teamName?: string,
  isHome: boolean,
  className?: string,
  settings: {
    assists: boolean,
    shots?: boolean,
    showResults: boolean,
    hidePlayers: boolean
  },
  colors: {
    primary: string,
    secondary: string
  }
}) {

  const T = useTranslations('cmresults')

  const percent = (n: number) => n ? Math.round(100 * n) : 0;
  // COUNT COLS-AMOUNT
  let columnsCount  = 1;

  if (settings.assists && settings.showResults) {
    columnsCount += 2
  }
  if(settings.shots && settings.showResults) {
    columnsCount += 2
  }
  
  const columnsBehaivor = isHome
    ? `minmax(min-content, 1fr) repeat(${columnsCount}, min-content)`
    : `repeat(${columnsCount}, min-content) minmax(min-content, 1fr)`


  function comparePlayers(a: EventStatistics$PlayerStat, b: EventStatistics$PlayerStat) {
    return (b.goals+b.assists) - (a.goals+a.assists);
  }


  return isHome 
    ? <ContentGrayContainer className={className}>
        {players.length > 0
          ? <div className={classNames(styles.players_grid)} style={{'--columns-behaivor' : columnsBehaivor} as any}>
            <div className={styles.player_header}>{T('Nr. / namn')}</div>
            {settings.assists && settings.showResults && <>
              <div className={classNames(styles.player_header, styles.text_center)}>{T('P')}</div>
              <div className={classNames(styles.player_header, styles.text_center)}>{T('A')}</div>
            </>}
            {settings.showResults && <>
              <div className={classNames(styles.player_header, styles.text_center)}>{T('G')}</div>
              {settings.shots && <>
                <div className={classNames(styles.player_header, styles.text_center)}>{T('G%')}</div>
                <div className={classNames(styles.player_header, styles.text_center)}>{T('SOG')}</div>
              </>}
            </>}
            {[...players].sort(comparePlayers).map((player, i, arr) => <React.Fragment key={i}>
              <div className={styles.the_player}>
                <span className={styles.player_number} style={{background: colors.primary, color: colors.secondary}}>
                  <span>{formatShirtNr(player.shirtNr)}</span> 
                </span>
                {!!player.name && !settings.hidePlayers && <PlayerName name={player.name} />}
              </div>
              {settings.assists && settings.showResults && <>
                <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.assists + player.goals}</div>
                <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.assists}</div>
              </>}
              {settings.showResults && <>
                <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.goals}</div>
                {settings.shots && <>
                  <div className={classNames(styles.text_center, styles.player_stat_unit)}>{percent(player.goals / player.shots)}</div>
                  <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.shots}</div>
                </>}
              </>}
              {i+1 < arr.length && <div 
                className={styles.grid_devider} 
                style={{'--gridColumn' : `1 / span ${columnsCount + 1}`} as any}
              ></div>}
            </React.Fragment>)}
          </div>
        : <h4 className={classNames(styles.player_header, 'custom-font')} style={{margin: 0}}>{T('Inga spelare med statistik från X i matchen', teamName)}</h4>
      }
    </ContentGrayContainer>
    : <ContentGrayContainer className={className}>
      {players.length > 0
        ? <div className={classNames(styles.players_grid, styles.reverted)} style={{'--columns-behaivor' : columnsBehaivor} as any}>
          {settings.shots && <>
            <div className={classNames(styles.player_header, styles.text_center)}>{T('SOG')}</div>
            <div className={classNames(styles.player_header, styles.text_center)}>{T('G%')}</div>
          </>}
          <div className={classNames(styles.player_header, styles.text_center)}>{T('G')}</div>
          {settings.assists && <>
            <div className={classNames(styles.player_header, styles.text_center)}>{T('A')}</div>
            <div className={classNames(styles.player_header, styles.text_center)}>{T('P')}</div>
          </>}
          <div className={classNames(styles.player_header, styles.text_right)}>{T('Namn / Nr.')}</div>
          {[...players].sort(comparePlayers).map((player, i, arr) => <React.Fragment key={i}>
            {settings.shots && <>
              <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.shots}</div>
              <div className={classNames(styles.text_center, styles.player_stat_unit)}>{percent(player.goals / player.shots)}</div>
            </>}
            <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.goals}</div>
            {settings.assists && <>
              <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.assists}</div>
              <div className={classNames(styles.text_center, styles.player_stat_unit)}>{player.assists + player.goals}</div>
            </>}
            <div className={classNames(styles.the_player, styles.text_right)}>
              <span className={styles.player_number} style={{background: colors.primary, color: colors.secondary}}>
                <span>{formatShirtNr(player.shirtNr)}</span> 
              </span>
              {!!player.name && !settings.hidePlayers && <PlayerName name={player.name} />}
            </div>
            {i+1 < arr.length && <div 
              className={styles.grid_devider} 
              style={{'--gridColumn' : `1 / span ${columnsCount + 1}`} as any}
            ></div>}
          </React.Fragment>)}
        </div>
        : <h4 className={classNames(styles.player_header, 'custom-font')} style={{margin: 0}}>{T('Inga spelare med statistik från X i matchen', teamName)}</h4>
      }
    </ContentGrayContainer>
}