import { faChevronRight, faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Match, Playoff, Round, Team } from "../../api/schema";
import { header } from "../../Loading/LoadingContent";
import Flag from "../Flag/Flag";
import MiddleEllipsisSpan from "../MiddleEllipsisSpan/MIddleEllipsisSpan";
import styles from './Playoff.module.scss';
import Button from "../Button/Button";


PlayoffGameComponent.fragment = Match.fragment({
    home: {
      team: {
        club: {
          nation: {}
        }
      }
    },
    away: {
      team: {
        club: {
          nation: {}
        }
      }
    },
    result: {}
});

PlayoffComponent.fragment = Playoff.fragment({
  rounds: [{
    matches: [Match.fragment({
      roundRank: {},
    }, PlayoffGameComponent.fragment)]
  }]
});

type props = {
  playoff: Playoff<typeof PlayoffComponent.fragment>
}

var theStart : {clientX:number, clientY:number};
var containerTop = 0;
var touchFromContainerTop = 0;
var touchFromContainerTopScaled = 0;
var scrollTopAtStart = 0;
var scrollContainerScrollLeftAtStart = 0;



function lin_scale(scrolledFactor:number) {
  return 1-0.5 * scrolledFactor;
}

const matchHeight = 100;
const matchWidth = 200;

export default function PlayoffComponent({playoff} : props) {
  const ref = useRef<HTMLDivElement>(null);

  

  const roundCount = playoff.rounds.filter(r => r.rank >= 0).length;
  const containerHeight = Math.pow(2, roundCount-1)*matchHeight;
  const containerWidth = matchWidth * roundCount;

  const uglyTopOffset = 50;

  function exp_scale(scrolledFactor:number) {
    const minScale = 3*matchHeight/containerHeight;

    return Math.pow(2, -roundCount * scrolledFactor)*(1-minScale) + minScale;
  }
  const scale = exp_scale;



  useEffect(() => {
    if (ref.current) {
      var scrollContainer = ref.current;
      var scrollCanvas = {scrollWidth: containerWidth};
      containerTop = ref.current.getBoundingClientRect().top;

     

      const onscroll = function(e:any) {
        if (!ref.current) return;
        var scrolled = scrollContainer.scrollLeft||0;
        var scrolledFactor = scrolled/(scrollCanvas.scrollWidth - scrollContainer.clientWidth);
        // var scale = 100 - 50* Math.pow(2,scrolledFactor)/2;
        var height = containerHeight * scale(scrolledFactor);
        scrollContainer.style.height = height+ "px";
      };
      
      const touchstart = function(e:any) {
        var scrolled = ref.current?.scrollLeft||0;
        var scrolledFactor = scrolled/(scrollCanvas.scrollWidth - scrollContainer.clientWidth);
        // var scale = 100 - 50*scrolledFactor;
        scrollTopAtStart = window.scrollY;

        const {clientX, clientY} = e.targetTouches[0];
        theStart =  {clientX, clientY};

        scrollContainerScrollLeftAtStart = scrollContainer.scrollLeft;

        touchFromContainerTop = clientY - containerTop + window.scrollY + uglyTopOffset;
        touchFromContainerTopScaled = touchFromContainerTop / (scale(scrolledFactor));

      }
      const touchmove = function(e:any) {
        e.preventDefault();
        // console.log("touchmove", e);
        const {clientX, clientY, pageX, pageY} = e.targetTouches[0];
        
        var scrolled = ref.current?.scrollLeft||0;
        var scrolledFactor = scrolled/(scrollCanvas.scrollWidth - scrollContainer.clientWidth);
        // var scale = 100 - 50*scrolledFactor;

        var touchFromContainerTopToAspireTo = touchFromContainerTopScaled * (scale(scrolledFactor));
        var diffY = touchFromContainerTopToAspireTo - touchFromContainerTop;
        var diffClientY = clientY - theStart.clientY;
        
        var diffClientX = clientX - theStart.clientX;

        scrollContainer.scrollLeft = scrollContainerScrollLeftAtStart - diffClientX;
        
        var columnIndex = roundCount - Math.ceil(scrollContainer.scrollLeft/matchWidth);
        var opacity = 1- (((scrollContainer.scrollLeft-1)*3)%(matchWidth*3))/matchWidth;
        
        
        var height = containerHeight * scale(scrolledFactor);
        
        scrollContainer.style.height = height+ "px";

        var scrollTop = (scrollTopAtStart - diffClientY + diffY);
        window.scrollTo({
          behavior: "auto",
          top: scrollTop
        });

        (ref.current as HTMLElement).childNodes.forEach((column,i) => {
            if( i == columnIndex ){
              (column as HTMLElement).style.setProperty("--scroll-opacity", "" + opacity);
            } else if( i > columnIndex ){
              (column as HTMLElement).style.setProperty("--scroll-opacity",  "0");
            } else {
              (column as HTMLElement).style.setProperty("--scroll-opacity",  "1");
            }
        })
        
      //  console.log({scale: scale(scrolledFactor), scrollTopAtStart ,diffClientY ,diffY, result: scrollTop})
      }
      
      //ref.current.addEventListener("scroll", onscroll);
      ref.current.addEventListener("touchstart", touchstart);
      ref.current.addEventListener("touchmove", touchmove);
      return () => {
        //ref.current?.removeEventListener("scroll", onscroll)
        ref.current?.removeEventListener("touchstart", touchstart)
        ref.current?.removeEventListener("touchmove", touchmove)
      }
    }
  }, [ref.current]);

  const hasBronze = playoff.rounds.some(r => r.rank===-1);

  return <div ref={ref} className={styles.container} style={{height: containerHeight, maxWidth: containerWidth}}>
    {/*<div style={{width: 1000, position:"relative", backgroundColor: "#e5e5f7", 
    // opacity: 0.8,
    // backgroundImage:  "linear-gradient(#444cf7 2px, transparent 2px), linear-gradient(90deg, #444cf7 2px, transparent 2px), linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px)",
    // backgroundSize: "50px 50px, 50px 50px, 10px 10px, 10px 10px",
    // backgroundPosition: "-2px -2px, -2px -2px, -1px -1px, -1px -1px"
    }}></div>*/}
    
    {playoff.rounds.filter(r => r.rank >= 0).map(round => <div className={styles.round} style={
            {height: "100%", 
            position:"absolute", 
            left: containerWidth-(round.rank+1)*matchWidth, 
            width: matchWidth}}>
      {round.matches.map(match => {
      
        const top = (100/Math.pow(2,round.rank+1) + (100 * match.roundRank.rank/(Math.pow(2,round.rank))));
        const top2 = (100/Math.pow(2,round.rank) + (100 * Math.round(((match.roundRank.rank-1)/2))/(Math.pow(2,round.rank-1))));
        let matchTop = "calc(" + top+"% - "+ matchHeight/2+"px)";

        let offsetDueToBronze = 41;

        if (round.rank === 0 && hasBronze) {
          matchTop = "calc(" + top+"% - "+ (matchHeight/2+offsetDueToBronze)   +"px)";
        }
        let homeLineHeight = 100/(Math.pow(2,round.rank+1)) + "%";
        let awayLineHeight = 100/(Math.pow(2,round.rank+1)) + "%";
        if (round.rank === 1 && hasBronze) {
          homeLineHeight = `calc(${homeLineHeight} - ${offsetDueToBronze-6}px)`;
          awayLineHeight = `calc(${awayLineHeight} + ${offsetDueToBronze+6}px)`;
        }
        const homeLineStraight = round.rank === 1 && playoff.rounds.length === 3 && hasBronze;
        const left = matchWidth/2;

        const teamLineOffset = 0.8;
        const headerOffset = -3;

        const homeWinner = match.result.homeState === "win";
        const awayWinner = match.result.awayState === "win";

        let startOffset = 0;
        if( homeWinner ){
         // startOffset = -teamLineOffset;
        } else if( awayWinner ){
         // startOffset = teamLineOffset;
        }

        return <>
            <div style={{position:"absolute", left:0, top: matchTop, zIndex: 1}}>
              <PlayoffGameComponent round={round} match={match}/>
              {round.rank === 0 && hasBronze && 
                <PlayoffGameComponent round={playoff.rounds.find(r => r.rank===-1)!} match={playoff.rounds.find(r => r.rank===-1)!.matches[0]!}/>
              }
            </div>
            {round.rank > 0 && (match.roundRank.rank % 2) === 0 && 
              <><div className={classNames(styles.homeLine, homeLineStraight&&styles.homeLineStraight)} style={{left:  left, top: "calc("+ top + "% - "+headerOffset+"px)", width: matchWidth, height: homeLineHeight}}>
                <div style={{top: startOffset+"rem", height: `calc(50% - ${teamLineOffset/2 + startOffset/2}rem)`}}></div>
                <div style={{ bottom: teamLineOffset+"rem", height: `calc(50% - ${teamLineOffset/2+ + startOffset/2}rem)`}}></div>
              </div>
              <div className={styles.homeArrow} style={{left:  left+matchWidth/2+3, bottom: "calc(" + (100-top2) + "% + " + teamLineOffset*0+ "rem)"}}>
                <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
              </div></>
            }
            {round.rank > 0 && (match.roundRank.rank % 2) === 1 && 
              <><div className={styles.awayLine} style={{left:  left, bottom: "calc(" + (100-top) + "% + "+headerOffset+"px)", width: matchWidth,height: awayLineHeight}}>
                <div style={{bottom: (-startOffset) + "rem", height: `calc(50% - ${teamLineOffset/2- startOffset/2}rem)`}}></div>
                <div style={{top: teamLineOffset+"rem", height: `calc(50% - ${teamLineOffset/2- startOffset/2}rem)`}}></div>
              </div>
              <div className={styles.awayArrow} style={{left:  left+matchWidth/2+3,  bottom: "calc(" + (100-top2) + "% - "+teamLineOffset*0+"rem)"}}>
                <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
              </div>
              </>
            }
        </>
      })}
  </div>)}

</div>
}

export function PlayoffBoxComponent({playoff, team} :  {playoff: Playoff<{}>, team?: Team}) {
  const ladder = useLink(playoff.ladder,{matches:[{match:{home:{team:{}}, away:{team:{}}}}]});
  const T = useTranslations("cmresults");

  const ladderMatch = ladder.matches.find(e => {
    if( team ){
      return e.match.home.team?.id === team.id || e.match.away.team?.id === team.id;
    } else {
      return e.rank === 1;
    }
  });

  const fragment = Match.fragment(PlayoffGameComponent.fragment, {round: {}}) ;

  const ladderRank = (ladderMatch?.rank || 0)

  const nextMatch = useLink((ladderRank > 0 ? ladder.matches.find(e => e.rank === Math.floor(ladderRank/2) ) : undefined)?.match, fragment);
  const homeMatch = useLink(ladder.matches.find(e => e.rank === ladderRank*2 )?.match, fragment);
  const awayMatch = useLink(ladder.matches.find(e => e.rank === ladderRank*2+1 )?.match, fragment);

  const match = useLink(ladderMatch?.match, fragment);
  if( match ){ 
    return <div className={"no-print"}><Link to={`/division/${playoff.id}`} className={styles.boxContainer}>
        <h3 className={"custom-font"}>{T('Ladder for X', T.select(playoff.name))}</h3>
        <div className={styles.matches}>
          {(homeMatch || awayMatch) && <div>
            {homeMatch && <PlayoffGameComponent match={homeMatch} round={homeMatch.round} team={team}/>}
            {awayMatch && <PlayoffGameComponent match={awayMatch} round={awayMatch.round} team={team}/>}
          </div>}
          <div className={styles.focus}><PlayoffGameComponent match={match} round={match.round} team={team}/> </div>
          {nextMatch && <div><PlayoffGameComponent match={nextMatch} round={nextMatch.round} team={team}/></div>}
          <Button className={styles.boxContainerButton} inverted size="sm">{T('Visa hela stegen')}</Button>
        </div>
       
      </Link></div>
  } else {
    return <></>
  }
}


function PlayoffGameComponent({match, round, team} : {match:Match<typeof PlayoffGameComponent.fragment>, round:Round, team?:Team}){

  console.log('ÖÖÖ', team)

  const T = useTranslations("cmresults");
  return <div className={styles.gameContainer}>
    <div className={styles.roundName}>
      {T.select(round.name)}
    </div>
    
    <Link to={`/match/${match.id}`}  className={styles.game}>
      <div className={styles.teams}>
        <div className={classNames(styles.team, styles.homeTeam, match.result.winner==="home" && styles.winner, team && team.id !== match.home.team?.id && styles.wrong_team)}>
          {match.home.team?.clubLogoUrl
            ? <div className={styles.team_logo_container}>
              <img 
                src={match.home.team?.clubLogoUrl} 
                alt={match.home.team?.name.fullName} 
                className={styles.team_logo} 
              />
            </div>
            : <Flag 
              code={match.home.team?.club.nation.code} 
              alt={match.home.team ? T.select(match.home.team.club.nation.code) : ''}
              size="sm"
            />
          }
          <MiddleEllipsisSpan text={T.select(match.home.name)} />
        </div>
        <div className={classNames(styles.team, styles.awayTeam, match.result.winner==="away" && styles.winner, team && team.id !== match.away.team?.id && styles.wrong_team)}>
        {match.away.team?.clubLogoUrl
            ? <div className={styles.team_logo_container}>
              <img 
                src={match.away.team?.clubLogoUrl} 
                alt={match.away.team?.name.fullName} 
                className={styles.team_logo} 
              />
            </div>
            : <Flag 
              code={match.away.team?.club.nation.code} 
              alt={match.away.team ? T.select(match.away.team.club.nation.code) : ''}
              size="sm"
            />
          }
          <MiddleEllipsisSpan text={T.select(match.away.name)} />
        </div>
      </div>
      <div className={classNames(styles.score,match.finished && styles.finished, match.live && styles.live)}>
        <div className={classNames(match.result.winner==="home" && styles.score_winner)}>{match.result.homeGoals}</div>
        
        <div className={classNames(match.result.winner==="away" && styles.score_winner)}>{match.result.awayGoals}</div>
      </div>
    </Link>
  </div>

}

