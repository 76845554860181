import { MapMarker } from "../Map/MapTypes";

/* 
  This function handles if two or more markers has the same long and lat. If so: this function alter the coordinates a bit to place them so they become visible on the map when zooming in
*/

function pseudoRandom(seed: number, id:number){
  const value = seed * id % 60 - 30;
  if( value >= 0 ){
    return value + 20;
  } else  {
    return value - 20;
  }
}

export default function distinctCoordinates(markers: MapMarker[]): MapMarker[] {
  const offset = 0.000002;

  const adjustedMarkers = [];

  for(var i= 0; i < markers.length; i++){
    const m1 = {...markers[i]};
    for(var j = i+1; j < markers.length; j++ ){
      
      const m2 = markers[j];
      if(m1.longitude === m2.longitude && m1.latitude === m2.latitude){
        m1.longitude += pseudoRandom(7823548726323, m1.id) * offset;
        m1.latitude += pseudoRandom(987349857987, m1.id) * offset;  
      }
    }
    adjustedMarkers.push(m1);
  }

  return adjustedMarkers;
}