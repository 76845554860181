import { faShieldAlt, faSlidersVSquare,faUsers } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useClient, useLink, useMutation } from "granular";
import { useEffect, useState } from "react";
import { MapNameClub } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { getColorString, useColors } from "../../colors";
import Flag from "../../components/Flag/Flag";
import HeaderSelect from "../../components/HeaderSelect/HeaderSelect";
import ListColumns from "../../components/ListColumns/ListColumns";
import MapOverlayClub from "../../Map/MapOverlayClub";
import { groupBy, setModalNoScroll } from "../../utils";
import styles from './RegistrationsPage.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button/Button";
import MapWithOverlay from "../../Map/MapWithOverlay";
import catchBodyScroll from "../../useBodyScroll";
import useCatchBodyScroll from "../../useBodyScroll";
import { Link, useNavigate, useParams } from "react-router-dom";
import MiddleEllipsisSpan from "../../components/MiddleEllipsisSpan/MIddleEllipsisSpan";
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import PendingPage from "../../components/Pending/PendingPage";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";


export default function RegistrationsPage() {
    
  const T = useTranslations("cmresults");
    const t = useCurrentTournament();
    const categories = useLink(t.regCategories, [{}]);
    const admin = useAdmin();

    const [map, setMap] = useState<any>(undefined);

    const registeredClubs = useLink(t.registeredClubs, []);


    const teamsPublished = t.teamsPublished || admin.overridePublished;
    const teamCountPublished = t.teamCountPublished || admin.overridePublished;
    const mapPublished = t.teamMapPublished;
    const clubsPublished = t.clubsPublished || admin.overridePublished;

    const allCategoriesOption = {
      id: "all" as "all",
      name: T('All categories')
    }

    const m = useParams<{clubId:string}>();



    const paramClub = m 
      ? registeredClubs.find(club => 
        m && club.nameClubId === parseInt(
          m.clubId || '0'
        )
      ) 
      : undefined


    const [categoryId, setCategoryId] = useState<number|'all'>(allCategoriesOption.id);
    const [selectedClub, setSelectedClub] = useState<MapNameClub | undefined>(undefined);
    const [overlayScrolled, setOverlayScrolled] = useState<true|false|undefined> (undefined);
  

    useEffect(() => {
      if(map) {
        if (hasSelection && selectedClub) {
          if(paramClub?.nameClubId === selectedClub.nameClubId) {
            setHasSelection(false);
            setOverlayScrolled(undefined);
          } else if( paramClub ){
            setSelectedClub(paramClub);
            setHasSelection(true);
            setOverlayScrolled(undefined);
          } else {
            setHasSelection(false);
            setOverlayScrolled(undefined);
          }
        } else if( paramClub ){
          setSelectedClub(paramClub);
          setHasSelection(true);
          setOverlayScrolled(undefined);
        }
      }
    }, [m?.clubId, map])
    
    const filteredClubs = () => {
      if(categoryId === 'all') {
        return registeredClubs
      } else {
        return registeredClubs.filter(c => c.nameClubId === selectedClub?.nameClubId || c.teams.find(team => team.categoryId === categoryId))
      }
    }

    const scrolling = useCatchBodyScroll();

    const sortedFilteredClubs = Object.fromEntries(Object.entries(
      groupBy(
        filteredClubs().sort((a, b) => a.name.localeCompare(b.name)), 
        filteredClub => filteredClub.name[0])));

    
    const [hasSelection, setHasSelection] = useState<boolean>(false);

    const selectClubId = (clubId: number) => {
      const club = registeredClubs.find(c => c.nameClubId === clubId);
      if( club ){
        selectClub(club);
      }
    }

    let navigate = useNavigate();

    const selectClub = (club: MapNameClub | undefined) => {
      if(club) {
        navigate('/clubs/' + club.nameClubId);
      } else {
        navigate('/clubs');
      }
    }

    const colors = useColors();
    const mobile =  window.matchMedia('(hover:none)').matches;

    return <>

        {clubsPublished ? <>
          <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent error={error} errorInfo={errorInfo} text={T('The map could not be loaded')} square />}>
          {mapPublished && <div className={styles.sticky}>
            <MapWithOverlay
              hideMap={mobile && overlayScrolled}
              clusterLabel={markers=>T('X registrations', markers.length)}
              setMap={setMap}
              markers={filteredClubs().map((club) => (
                {
                  ...club,
                  color: getColorString(colors.primary),
                  type: 'club',
                  id: club.nameClubId
                }
              ))} 
              controls={
                teamsPublished ? <HeaderSelect 
                  icon={faSlidersVSquare} 
                  legend={T('Categories')}
                  className={classNames(styles.header_select)}
                  onChange={id=>{
                    setCategoryId(id)
                    selectClub(undefined)
                  }} 
                  selectedId={categoryId} 
                  options={[allCategoriesOption, ...categories]} 
                /> : undefined
              }
              scrolling={scrolling}
              minInitWidth="registrations" 
              selectedId={hasSelection ? selectedClub?.nameClubId :  undefined} 
              onChange={(marker) => {
                selectClub(marker)

              }} 
              zoomKey={categoryId}
              visible={hasSelection}
              selectedTitle={
                <div className={classNames(styles.clubName,"max-75")}>
                  <Link to={"/"}>{T('...')}</Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
                  <Link to={"/clubs"}>{T('Karta')}</Link>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
                  <h1 className="custom-font">
                    {/*<FontAwesomeIcon icon={faShieldAlt} />*/} <MiddleEllipsisSpan inline text={selectedClub?.name || ''}/>
                  </h1>
                  {/*<div className={styles.button}>
                    <Button inverted onClick={() => {
                      navigate('/clubs')
                    }}>
                      <>{T('Stäng')}</>
                    </Button>
                  </div>*/}
                </div>
              }
              selectedOverlay={
                selectedClub ? <MapOverlayClub key={selectedClub.nameClubId} selectedClub={selectedClub} selectClub={selectClubId} setScrolled={setOverlayScrolled} scrolled={mobile && overlayScrolled} selectCategory={setCategoryId}/> : <></>
              }
            />
            <div className={classNames(styles.listHeader, scrolling && styles.scrolled)}>
              <h2 className={classNames("max-75","custom-font")}>
                <span> 
                  {categoryId === 'all'
                    ? T('All registered clubs')
                    : T('Clubs registered to X', categories.find(category => category.id === categoryId)?.name)}
                </span>
                <small>
                    <FontAwesomeIcon icon={faShieldAlt}/>{T('X club(s):X', filteredClubs().length)}
                    {teamsPublished && <>
                        <FontAwesomeIcon icon={faUsers}/>
                        {T('X team(s):X ',filteredClubs().reduce((total, club) => total + club.teams.filter(t => categoryId === "all" || categoryId === t.categoryId).length,0))}
                        {teamCountPublished && categoryId !== 'all' && categories.find(category => category.id === categoryId)!.teamLimit>0 && <>
                          {T('of max. X', categories.find(category => category.id === categoryId)?.teamLimit)}
                        </>}
                        
                    </>}
                </small>
              </h2>
            </div>
          </div>}
          </ErrorBoundary>

        
        <div className={classNames(styles.map_body,"max-75")}>
          <ErrorBoundary fallback={({error, errorInfo}) => <ErrorComponent 
            error={error} 
            errorInfo={errorInfo} 
            text={T('The registrations could not be loaded')}
          />}>
            <ListColumns 
              list={Object.entries(sortedFilteredClubs).map(entry => ({header: entry[0], items: entry[1]}))} 
              render={(club) => (
                <>
                  {/*<Link to={`/clubs/${club.nameClubId}`}>test</Link>*/}
                  <button 
                    key={club.nameClubId}
                    onClick={() => {
                      selectClub(club);
                    }}
                    className={classNames(hasSelection && club.nameClubId === selectedClub?.nameClubId && styles.selected)} 
                    aria-label={`${club.name}, från ${club.nationCode}, ${club.teams.length} lag`}
                  >

                    {club.clubLogoUrl
                      ? <div className={styles.club_logo_container}>
                        <img src={club.clubLogoUrl} alt={club.name} />
                      </div>
                      : <Flag code={club.nationCode} alt={T('Nation code: X', club.nationCode)} />
                    }
                    <span>
                      <span className={styles.club_name}>{club.name}</span> 
                      {teamsPublished && <span className={styles.teams_count}>({club.teams.filter(t => categoryId === "all" || categoryId === t.categoryId).length})</span>}
                    </span>
                  </button>
                </>
              )}
              />
            </ErrorBoundary>
        </div>
        </>: <PendingPage text={T('The registrations are not published yet')} color="rgb(var(--clr-primary))"/>}
    </>

}