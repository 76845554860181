import { MapNameClub, useNameClub } from "../api/schema";
import { useTranslations } from "cupman-utils";
import Flag from "../components/Flag/Flag";
import styles from './MapOverlayClub.module.scss';
import TeamRow from "../components/TeamRow/TeamRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faUsers } from "@fortawesome/pro-regular-svg-icons";
import LinkButton from "../components/Button/LinkButton";
import classNames from "classnames";
import { renderLinks } from "../utils";
import { useEffect, useRef } from "react";
import Games from "../components/Game/Games";
import { useRefScrolledFromTop } from "../utils/HasScrolledFromTop";
import { useAdmin } from "../components/AdminBanner/AdminBanner";
import { useCurrentTournament } from "../App";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import TeamLogo from "../components/ClubTeamHead/TeamLogo";
import Dress from "../components/ClubTeamHead/Dress";
import TeamLogoDress from "../components/ClubTeamHead/TeamLogoDress";

export default function MapOverlayClubInner({club,selectClub,selectCategory,scrolled,setScrolled} : 
      {club: MapNameClub,selectClub: (clubId: number) => void, selectCategory: (clubId: number|'all') => void, setScrolled: (scrolled: boolean) => void, 
      scrolled?: boolean}) {

  const T = useTranslations("cmresults");
  const t = useCurrentTournament();
  const admin = useAdmin();
  const nameClub = useNameClub({id:club.nameClubId}, {
    registeredTeams:[{team: {matches:[Games.GameFragment]}}], 
    address:{nation:{}}, 
    description: {},
    shirt: {},
    nation: {}
  }
    
  );

  const onClick = (url:string)=>{
      
      let match = url.match("(?:/result)?/club/(\\d+)");
      if(match){
        selectClub(parseInt(match[1]));
      }

      match = url.match("(?:/result)?/category/(\\d+)");
      if(match){
        selectCategory(parseInt(match[1]));
      }

      match = url.match("/categories");
      if(match){
        selectCategory('all');
      }

  };

  const allMatches = nameClub.registeredTeams.flatMap(regTeam => regTeam.team ? regTeam.team.matches : []);
  const playedMatches = allMatches.filter(match => match && match.start < Date.now());
  const comingMatches = allMatches.filter(match => match && match.start > Date.now());

  const container = useRef(null);

  const published = t.schedulePublished || admin.overridePublished;
  const showTeams = t.teamsPublished || admin.overridePublished;

  useRefScrolledFromTop(container.current, setScrolled);


  useEffect(()=>{
    if( container ){
      (container.current as any).scrollTo(0,0);
    }
  }, [club.nameClubId])

  return <div>
    <div className={styles.top_shadow}></div>
    <div className={classNames(styles.club_link, scrolled && styles.scrolled)}>

      {/*<LinkButton size="lg" url={`/club/${club.nameClubId}`}>
        {T('View results')} 
        <div className={styles.club_link_shadow}>
          <div></div>
        </div>
</LinkButton>*/}
      
    </div>
    <div ref={container} className={classNames(styles.map_overlay_content_container)}>
      <div className="max-50 last-item">
        <div className={styles.scroll_container}></div>

        <h4 className={styles.address_mobile}>
          <Flag code={nameClub.address.nation.code} alt={T.select(nameClub.address.nation.name)} /> {T.select(nameClub.address.nation.name)}
          <FontAwesomeIcon icon={faCity}/>{nameClub.address.city}
        </h4>

        <div className={styles.club_visuals}>

          {nameClub.shirt && <TeamLogoDress 
            logoImg={nameClub.clubLogoUrl} 
            dress={nameClub.shirt} 
            nationCode={nameClub.nation.code} 
            sport={t.subcup.sport.type} /* t.subcup.sport.type */
            className={styles.map_team_container}
          />}
          
          <h4 className={styles.address_desktop}>
            <Flag code={nameClub.address.nation.code} alt={T.select(nameClub.address.nation.name)} /> {T.select(nameClub.address.nation.name)}
            <FontAwesomeIcon icon={faCity}/>{nameClub.address.city}
          </h4>
        </div>

        <p className={styles.description}>
          {renderLinks(T.select(nameClub.description.origin),onClick)} 
          {showTeams ? renderLinks(T.select(nameClub.description.participation),onClick) : undefined}
          {showTeams ? renderLinks(T.select(nameClub.description.performance),onClick) : undefined}
        </p>

        {showTeams && <div>
          <h4><FontAwesomeIcon icon={faUsers}/> {T('X team(s):X from Y', nameClub.registeredTeams.length, club.name)}</h4>
          <div>
            {nameClub.registeredTeams.map((team) => {

              return <TeamRow key={team.id} className={styles.team_row} team={
                  {
                    name: T.select(team.categoryName) || '', 
                    id: team.team?.id
                  }
                } />}
              )}
          </div>
        </div>}

        <p className={styles.description}>
          {renderLinks(T.select(nameClub.description.lastYearRegistrations),onClick)} {renderLinks(T.select(nameClub.description.lastYearPerformance),onClick)}
        </p>
        <p className={styles.description}>
          {renderLinks(T.select(nameClub.description.distance),onClick)} {renderLinks(T.select(nameClub.description.area),onClick)}
        </p>
        
      </div>

      {published && <div className={styles.buttonbar}>
        <LinkButton size="lg" url={nameClub.matchesCalendarUrl} >
          <FontAwesomeIcon icon={faDownload} />
          <span>{T('iCal')}</span>
        </LinkButton>

        <LinkButton size="lg" url={nameClub.matchesExcelUrl} >
          <FontAwesomeIcon icon={faDownload} />
          <span>{T('Excel')}</span>
        </LinkButton>
      </div>}

      {comingMatches && <Games published={published} games={comingMatches} title={T('Coming games')} order='asc' />}
      {playedMatches && <Games published={published} games={playedMatches} title={T('Last played games')} order='desc' />}
    </div>
  </div>
    
}