import { Team$shirt as Shirt, Sport$SportTypes } from '../../api/schema';
import Dress from './Dress';
import styles from './TeamLogo.module.scss';
import classNames from 'classnames';

type Props = {
  logoImg?: string,
  dress?: Omit<Shirt,'__typename'>,
  nationCode: string,
  sport: Sport$SportTypes | undefined,
  scrolled?: boolean | undefined,
  className?: string
}

export default function TeamLogoDress ({logoImg, dress, nationCode, sport, scrolled, className} : Props) {


  return <div className={classNames(scrolled && styles.scrolled)}>
    {logoImg 
      ? <img src={logoImg} alt="cat" className={styles.team_logo} />
      : <div 
          className={classNames(styles.team_icon_container, className && className)}
        >
          <Dress sport={sport} dress={dress} nationCode={nationCode} />
        </div>}
      </div>

}