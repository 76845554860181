import classNames from "classnames";
import { Team$shirt } from "../../api/schema";
import styles from './TeamsColorCircle.module.scss';

type props = {
  shirt?: Team$shirt, 
  noBorder?: boolean, 
  small?: boolean,
  className?: string
}

export default function TeamsColorCircle ({shirt, noBorder, small, className} : props) {


  return <div className={classNames(styles.team_color_circle, noBorder && styles.no_border, small && styles.small, className && className)} style={{
    '--clr-team-primary' : shirt 
      ? shirt.color1 
      : 'var(--clr-gray-200)',
    '--clr-team-secondary' : shirt 
      ? shirt.color2 
      : 'var(--clr-gray-200)'
  } as any}>
    <div className={styles.clr_primary}></div>
    <div className={styles.clr_secondary}></div>
  </div>
}