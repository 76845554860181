import styles from './MatchSummary.module.scss';

import Score from "../Score/Score";
import MatchTeam from "./MatchTeam";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomIconFa from '../CustomIconFa/CustomIconFa';
import { Match, Sport$SportTypes } from '../../api/schema';
import ActiveCountdown from "../Countdown/ActiveCountdown";
import { useCurrentTournament } from '../../App';
import { useTranslations } from 'cupman-utils';
import classNames from 'classnames';
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { faCalendar, faClock } from '@fortawesome/pro-light-svg-icons';
import { useRef } from 'react';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';
import LinkButton from '../Button/LinkButton';
import MiddleEllipsisSpan from '../MiddleEllipsisSpan/MIddleEllipsisSpan';
import { capitalize } from '../../utils';

MatchSummary.fragment = {
  home: MatchTeam.fragment,
  away: MatchTeam.fragment,

  result: {},
  arena: {
    location: {}
  },
  video: {
   
  },
  division: {
    stage: {
      category: {
      }
    }
  }
}

type props = {
  game: Match<typeof MatchSummary.fragment>
}


export default function MatchSummary({game} : props) {

  console.log('img',game.home.team?.clubLogoUrl)
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const sport = t.subcup.sport.type;


  const getGlow = () => {
    if(game.video && game.live && !game.finished) 
      return styles.live_now;
    
    if(!game.video && game.live && !game.finished) 
      return styles.reporting_now;
    
    return false;
  }

  const ref = useRef<HTMLDivElement>(null);

  const sticked = useScrolledFromTop(170);
  const cn = classNames(styles.match_summary_container, getGlow(), sticked ? styles.sticked : undefined);

  const scrolled = (useScrolledFromTop(200));

  const T = useTranslations('cmresults');
  
  return <>
    
    <div className={cn} ref={ref}>
      <div className={styles.overlay_black}>

        <div className={styles.teams_logos_bg}>
          <div className={classNames(styles.logo_container, styles.home_logo_container)} style={{'--team-logo-url' : `url(${game.home.team?.clubLogoUrl})`} as any}>
            <div className={styles.team_logo_gradient_overlay}></div>
          </div>
          <div className={classNames(styles.logo_container, styles.away_logo_container)} style={{'--team-logo-url' : `url(${game.away.team?.clubLogoUrl})`} as any}>
            <div className={styles.team_logo_gradient_overlay}></div>
          </div>
        </div>



        <div className={classNames("max-75", styles.teams_presentation_content)}>

          <div className={classNames(styles.teams, sticked ? styles.sticked : undefined)}>
            <MatchTeam
              side='home'
              sticked={sticked}
              team={game.home}
              sport={sport as Sport$SportTypes | undefined}
              logoClassName={styles.logo_glow}
            />

            {(game.live || game.finished ) && !game.division.stage.category.hideMatchResults
              ? <div className={styles.score_container}>
                  <Score
                    result={game.result}
                    className={classNames(
                      styles.scoreSize, sticked 
                      ? styles.sticked 
                      : undefined
                    )}
                    sticked={sticked}
                  />
                  {game.arena && <>
                    {t.mapPublished
                      ? <LinkButton
                        url={`/map/${game.arena.location.id}`}
                        className={classNames(styles.matchArenaLink, scrolled && styles.scrolled)}
                      >
                        <span className={styles.arena_info}>
                          <CustomIconFa 
                            className={styles.arenaIcon} 
                            icon='icon-arena' 
                            fontWeight="bold" 
                            white 
                          />
                          <MiddleEllipsisSpan className={styles.arenaTitle_desktop} text={game.arena.completeName} />
                          <span className={styles.arenaTitle_mobile}>{game.arena.completeName}</span>
                        </span>
                      </LinkButton>
                      : <span className={classNames(styles.arena_info, scrolled && styles.scrolled)}>
                        <CustomIconFa 
                          className={styles.arenaIcon} 
                          icon='icon-arena' 
                          fontWeight="bold" 
                          white 
                        />
                        <MiddleEllipsisSpan inline className={styles.arenaTitle_desktop} text={game.arena.completeName} />
                        <span className={styles.arenaTitle_mobile}>{game.arena.completeName}</span>
                      </span>
                    }
                  </>}
                  {/* game.arena && <div className={classNames(styles.buttonbar_outer, "no-print")}>
                    <div className={styles.buttonbar}>
                      <div>
                        {t.mapPublished ? (<LinkButton size="lg" url={`/map/${game.arena.location.id}`} >
                          <CustomIconFa className={styles.arenaIcon} icon='icon-arena' fontWeight="bold" white />
                          <span>{game.arena.completeName}</span>
                        </LinkButton>) : (<div> 
                          <CustomIconFa className={styles.arenaIcon} icon='icon-arena' fontWeight="bold" />
                          <span style={{paddingLeft: ".5rem"}}>{ T('Field')}: {game.arena.completeName}</span>
                        </div>)}
                      </div>
                    </div>

                  </div> */}
                </div>
              : <div className={classNames(styles.countdown, sticked && styles.sticked)}>
                  <ActiveCountdown to={game.start} color={"100, 100, 100"}/>
                </div>
            }

            <MatchTeam
              side='away'
              sticked={sticked}
              team={game.away} 
              sport={sport as Sport$SportTypes | undefined}
              logoClassName={styles.logo_glow}
            />
          </div>

          <div className={classNames(styles.game_date_time, sticked ? styles.sticked : undefined)}>
            <div>
              <span>
                <FontAwesomeIcon icon={faClock}/> {formatDate(game.start, 'HH:mm')}
              </span> 
              <span>
                <FontAwesomeIcon icon={faCalendar}/> {capitalize(formatDate(game.start, 'eee. d MMM'))}
              </span>
              {/*<span>
                <CustomIconFa icon='icon-arena' fontWeight="bold" white />{game.arena?.completeName}
              </span>*/}
            </div>
          </div>
        </div>




      </div>
    </div>
  </>
}


