import { useTranslations } from "cupman-utils";
import { Link } from "react-router-dom";
import { MatchActor, Sport$SportTypes } from "../../api/schema";
import DressBasket from "../Dress/DressBasket";
import DressSoccer from "../Dress/DressSoccer";
import Flag from "../Flag/Flag";
import styles from './MatchTeam.module.scss';
import { CSSTransition } from "react-transition-group"
import { useEffect, useRef, useState } from "react";
import MiddleEllipsisSpan from "../MiddleEllipsisSpan/MIddleEllipsisSpan";
import TeamsColorCircle from "../TeamsColorCircle/TeamsColorCircle";
import Dress from "../ClubTeamHead/Dress";
import TeamLogo from "../ClubTeamHead/TeamLogo";
import classNames from "classnames";
var cn = require('classnames');

MatchTeam.fragment = MatchActor.fragment({
  shortName: {},
  team: {
    shirt: {},
    club: {
      nation: {}
    }
  }
})

type props = {
  team: MatchActor<typeof MatchTeam.fragment>,
  sport?: Sport$SportTypes | undefined,
  className?: string,
  sticked?: boolean,
  side?: 'home' | 'away',
  logoClassName?: string
}

export default function MatchTeam ({team, sport, className, sticked, side, logoClassName} : props) {


  const T = useTranslations('cmresults');

  const showDress = team.team
    ? team.team.shirt
      ? {
        ...team.team.shirt,
        code: team.team.club.nation.code,
        type: team.team.shirt.type,
        nationName: T.select(team.team.club.nation.name)
      }
      : undefined
    : {
      anti: '#000000',
      color1: '#dadad1',
      color2: '#9f9f97',
      color3: '#9f9f97',
      code: undefined,
      type: 'unknown' as 'vertical',
      nationName: '',
      href: ''
    };

  const animationTimeOut = 500;

  const nodeRef = useRef<any>(null);

  const [dressHeight, setDressHeight] = useState<undefined | number>(undefined)

  useEffect(() => {
    if( nodeRef.current ){
      setDressHeight(nodeRef.current.scrollHeight);
    } 
  }, [nodeRef])

  console.log('dress', !sticked && !!showDress)

  return <>
    <Link to={`/team/${team.id}`} 
      className={cn(styles.team_container, sport === 'basketball' && styles.basket, className, sticked ? styles.sticked : undefined)} 
      style={{
        '--dress-timeout' : `${animationTimeOut}ms`,
          opacity: team.team ?  '1' : '.4',
        }  as any 
      }
    >

      {showDress && <CSSTransition
        in={!sticked && !!showDress}
        timeout={animationTimeOut}
        nodeRef={nodeRef}
        classNames={{
          enter: styles.dressEnter,
          enterActive: styles.dressEnterActive,
          enterDone: styles.dressEnterDone,
          exit: styles.dressExit,
          exitActive: styles.dressExitActive,
          exitDone: styles.dressExitDone
        }}
  
        onEnter={() => setDressHeight(nodeRef.current.scrollHeight)}
      >
        <div className={styles.dress_wrapper} style={{'--dress-height' : `${dressHeight}px`} as any} ref={nodeRef}>

          {team.team?.clubLogoUrl
            ? <div className={styles.logo_container}>
              <img src={team.team?.clubLogoUrl} alt={team.team?.name.fullName} className={styles.team_logo} />
            </div>
            : <Dress sport={sport} dress={showDress} nationCode={team.team?.club.nation.code} className={cn(styles.dress, sport === 'basketball' && styles.basket)} />
          }
          
        </div>

      </CSSTransition>}

      <div className={cn(styles.country_container, sticked ? styles.sticked : undefined)}>
        {showDress && <Flag 
          code={showDress.code} 
          borderColor="rgb(var(--clr-gray-400))"
          alt={showDress.nationName}
        />}
        {team.team && <span className={styles.country}>
          <span>{T.select(team.team.club.nation.name)}</span>
          {showDress && <span>{showDress.code}</span>}
        </span>}
        
      </div>

      <div style={{width: '100%'}}>
        <div className={cn(styles.name, sticked ? styles.sticked : undefined)}>

          {showDress && sticked && side === 'home' && <>
            {team.team?.clubLogoUrl
              ? <div className={styles.logo_container_sm}>
                <img src={team.team?.clubLogoUrl} alt={team.team.name.fullName} className={logoClassName} />
              </div>
              : <TeamsColorCircle shirt={team.team?.shirt} />
            }
            <Flag 
              code={showDress.code} 
              borderColor="rgb(var(--clr-gray-400))"
              alt={showDress.nationName}
            />
          </>} 

          <span className={styles.full_name}>
            <MiddleEllipsisSpan text={T.select(team.name)}/>
          </span>
          
          {team.shortName && <span className={styles.short_name}>{T.select(team.shortName.name)}</span>}

          {showDress && sticked && side === 'away' && <>
            <Flag 
              code={showDress.code} 
              borderColor="rgb(var(--clr-gray-400))" 
              alt={showDress.nationName}
            />
            {team.team?.clubLogoUrl
              ? <div className={styles.logo_container_sm}>
                <img src={team.team?.clubLogoUrl} alt={team.team.name.fullName} className={logoClassName} />
              </div>
              : <TeamsColorCircle shirt={team.team?.shirt} />
            }
          </>}
        </div>
      </div>
    </Link>
  </>
}