import { faWhistle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { MatchPlayerFoul } from "../../api/schema";
import { capitalize, formatShirtNr } from "../../utils";
import { getSideClass } from "./LiveFeed";
import styles from './PlayerFoul.module.scss';
import TimelineMinutes from "./TimelineMinutes";

export default function PlayerFoul ({event, code, hidePlayers} : {event: MatchPlayerFoul, code: string | undefined, hidePlayers: boolean}) {

  const T = useTranslations('cmresults');

  return <div className={
    classNames(
      styles.player_foul_container, 
      event.side === 'away' 
        ? styles.away 
        : styles.home,
      getSideClass(event.side)
    )
  }>
    <div className={styles.player_foul_inner_container}>
      <div className={styles.foul_info}>
        
        <div className={styles.foul_container}>
          <span className={styles.foul_type}>
            <span>{T('Foul:')}</span> {capitalize(event.foulType)}
          </span>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faWhistle} className={styles.whistle}/>
          </span>
        </div>

        <div className={styles.player_container}>
          <span className={styles.name}>{!hidePlayers && event.playerName}</span>

          {event.playerNr && <span className={styles.shirt_nr}>#{formatShirtNr(event.playerNr)}</span>}
        </div>

      </div>
    </div>
    <TimelineMinutes time={event.displayRelativeTime||event.relativeTime} side={event.side} type={event.__typename} />
    <div></div>
  </div>
}