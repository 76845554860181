import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { EventStatistics$GoalkeeperStat, EventStatistics$PlayerStat, Match } from '../../api/schema';
import styles from './TeamMembers.module.scss';
import { useCurrentTournament } from '../../App';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import Button from '../Button/Button';
import { useRef, useState } from 'react';
import TeamsColorCircle from '../TeamsColorCircle/TeamsColorCircle';
import ToggleButtons from '../Button/ToggleButtons';
import OutfieldPlayersTable from './OutFieldPlayersTable';
import GoalkeepersTable from './GoalKeepersTable';

TeamMembers.fragment = Match.fragment({
  home: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  away: {
    team: {
      club: {
        nation: {}
      },
      shirt: {}
    }
  },
  division: {
    category: {}
  },
  feed: {
    statistics: {}
  }
});

type props = {
  game: Match<typeof TeamMembers.fragment>
}

export default function TeamMembers({game} : props) {
  const T = useTranslations('cmresults');
  
  const t = useCurrentTournament();
  const cat = game.division.category;
  const sport  = t.subcup.sport;
  
  const isIcehockey = sport.type === 'icehockey';
  const hasGoalkeepers = isIcehockey && cat.registerShots;

  const settings = {
    assists: cat.registerAssists,
    shots: cat.registerShots,
    showResults: !game.division.category.hideMatchResults,
    hidePlayers: game.division.category.hidePlayers
  }

  const [selectedMatchActor, setSelectedMatchActor] = useState<'home' | 'away'>('home');


  return (
    <div className={classNames('max-75')} >
      <div className={styles.player_statistics_header}>
        <h2 className={classNames(styles.header,"custom-font")}>
          <FontAwesomeIcon icon={faUser} /> {T('Spelare med statistik')}
        </h2>
      </div>
      <div className={classNames(styles.buttons, !hasGoalkeepers && styles.margin)}>
        <ToggleButtons padding={5}>
          <Button 
            onClick={() => setSelectedMatchActor('home')} 
            disabled={selectedMatchActor === 'home'}
            className={styles.team_button}
          >
            <TeamsColorCircle shirt={game.home.team?.shirt} small={true} /> <span className={styles.player_statistics_team_name}>{T.select(game.home.name)}</span>
          </Button>
          <Button 
            onClick={() => setSelectedMatchActor('away')} 
            disabled={selectedMatchActor === 'away'}
            className={styles.team_button}
          >
            <TeamsColorCircle shirt={game.away.team?.shirt} small={true} /> <span className={styles.player_statistics_team_name}>{T.select(game.away.name)}</span>
          </Button>
          </ToggleButtons>
      </div>

      {hasGoalkeepers && <div>
        <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
          {T('Målvakter')}
        </h3>
        
        <div className={styles.teams_container}>
          {game.home.team && <GoalkeepersTable
            className={selectedMatchActor === 'home' ? styles.visible : styles.hidden}
            isHome={true}
            players={game.feed.statistics.home.goalkeepers}
            colors={{
              primary: game.home.team.shirt?.color1 || '#000',
              secondary: game.home.team.shirt?.anti || '#fff'
            }}
            settings={settings}
          />}
          {game.away.team && <GoalkeepersTable
            className={selectedMatchActor === 'away' ? styles.visible : styles.hidden}
            isHome={false}
            colors={{
              primary: game.away.team.shirt?.color1 || '#000',
              secondary: game.away.team.shirt?.anti || '#fff'
            }}
            players={game.feed.statistics.away.goalkeepers}
            settings={settings}
          />}
        </div>
      </div>}

      {<div>
        {hasGoalkeepers && <h3 className={classNames(styles.statistics_group_header, 'custom-font')} style={{marginTop: '1rem'}}>
          {T('Utespelare')}
        </h3>}
        <div className={styles.teams_container}>
          {game.home.team && <OutfieldPlayersTable
            className={selectedMatchActor === 'home' ? styles.visible : styles.hidden}
            teamName={T.select(game.home.name)}
            isHome={true}
            players={game.feed.statistics.home.players}
            colors={{
              primary: game.home.team.shirt?.color1 || '#000',
              secondary: game.home.team.shirt?.anti || '#fff'
            }}
            settings={settings}
          />}
          {game.away.team && <OutfieldPlayersTable
            className={selectedMatchActor === 'away' ? styles.visible : styles.hidden}
            teamName={T.select(game.away.name)}
            isHome={false}
            colors={{
              primary: game.away.team.shirt?.color1 || '#000',
              secondary: game.away.team.shirt?.anti || '#fff'
            }}
            players={game.feed.statistics.away.players}
            settings={settings}
          />}
        </div>
      </div>}
    </div>
  )
}