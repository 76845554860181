import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { MatchResult } from '../../api/schema';
import styles from './Score.module.scss';


type props = {
  result: MatchResult,
  className?: string,
  above?: any,
  below?: any,
  sticked?: boolean
}


export default function Score ({result, className, above, below, sticked} : props) {

  console.log(result)

  
 return <div className={classNames(styles.order, sticked ? styles.sticked : styles.not_sticked)}>
      {above && <div className={styles.extra_info}>
        {above}
      </div>}
      <InnerScore result={result} className={className}/>
      {below && <div className={styles.extra_info}>
        {below}
      </div>}
    </div>
     
 
}


function InnerScore({result, className} : {result: MatchResult, className?: string}){
  const T = useTranslations('cmresults');

  const leader = result.homeGoals > result.awayGoals
    ? 'home'
    : result.homeGoals === result.awayGoals
      ? undefined
      : 'away'

  
  if( result.presentation === "Result"){
    return <div className={classNames(styles.score, className)}>
      <span className="visually_hidden">{T('Score is')}</span>
      <span className={classNames(styles.team, leader === "home" && styles.bold)}>
        {result.homeGoals}
      </span> 
      {((result.homeGoals !== undefined && result.homeGoals > 9) && (result.awayGoals !== undefined && result.awayGoals > 9)) 
        ? <span>-</span>
        : <span> - </span>
      }
      <span className={classNames(styles.team,  leader === "away" && styles.bold)}>{result.awayGoals}</span>
    </div>;
  } else if (result.presentation === "ResultsPerSet") {
    return <div className={classNames(styles.score, className, styles.period_score)}>
      {result.periodScores.map((ps, i) => {
        return <>
          <span className={classNames(styles.team, ps.homeGoals > ps.awayGoals && styles.bold)}>{ps.homeGoals}</span> 
          <span>-</span>
          <span className={classNames(styles.team, ps.awayGoals > ps.homeGoals && styles.bold)}>{ps.awayGoals}</span>
          {i < result.periodScores.length-1 && <span className={styles.period_separator}>/</span>}
        </>
      })}
    </div>;
  } else if( result.presentation === "Points"){
    return <div className={classNames(styles.score, className)}>
      <span className="visually_hidden">{T('Score is')}</span>
      <span className={classNames(styles.team,  result.winner === "home" && styles.bold)}>{result.homePoints}</span> 
      {((result.homePoints !== undefined && result.homePoints > 9) && (result.awayPoints !== undefined && result.awayPoints > 9)) 
        ? <span>-</span>
        : <span> - </span>
      }
      <span className={classNames(styles.team,  result.winner === "away" && styles.bold)}>{result.awayPoints}</span>
    </div>;
  } else{
    return <></>
  }
  
}

