import { useTranslations } from "cupman-utils"
import { Team$statistics } from "../../../api/schema"
import ContentGrayContainer from "../../ContentGrayContainer";
import styles from './TeamPageStatistics.module.scss';
import classNames from "classnames";
import MatchesBar from "./MatchesBar";
import GoalsWonLostBar from "./GoalsWonLostBar";
import SingleValue from "./SingleValue";
import OutfieldPlayersTable from "../../TeamMembers/OutFieldPlayersTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";

export type TeamColors = {
  color1: string,
  color2: string,
  color3: string,
  anti: string
}

type Props = {
  statistics: Team$statistics,
  teamColors: TeamColors,
  settings: {
    greenCards: boolean,
    yellowCards: boolean,
    redCards: boolean,
    showResults: boolean,
    assists: boolean,
    hidePlayers: boolean,
  }
}



export default function FootballStatistics ({statistics, teamColors, settings} : Props) {


  const T = useTranslations('cmresults');

  const positiveGreen = {r:165, g:219, b:127};
  const negativeRed = {r:255, g:105, b:105}

  return <>
    <GroupHeader label={`${T('Mål')}`}/>
    <ContentGrayContainer className={styles.gray_container}>
      <GoalsWonLostBar statistics={statistics} colors={{
        positive: positiveGreen,
        negative: negativeRed
      }} />
    </ContentGrayContainer>

    {(settings.greenCards || settings.redCards || settings.yellowCards) && <div>
      <GroupHeader label={`${T('Kort')}`}/>
      <div className={styles.single_values}>
        {settings.greenCards && <SingleValue value={statistics.greenCards} backgroundColor="#DFFFD6" color="#000000" label={T('Gröna')} />}
        {settings.yellowCards && <SingleValue value={statistics.yellowCards} backgroundColor="#FFFACA" color="#000000" label={T('Gula')} />}
        {settings.redCards && <SingleValue value={statistics.redCards} backgroundColor="#FFE6E6" color="#000000" label={T('Röda')} />}
      </div>
    </div>}

    {statistics.players.length > 0 && <>
      <h2 className={classNames(styles.player_statistics_header, 'custom-font')}>
        <FontAwesomeIcon icon={faUser}/> {T('Spelarstatistik')}
      </h2>
      <OutfieldPlayersTable 
        players={statistics.players} 
        isHome 
        colors={{
          primary: teamColors.color1,
          secondary: teamColors.anti
        }}
        settings={settings}
      />
    </>}
  </>
}

export const GroupHeader = ({label} : {label:string}) => {
return <h3 className={classNames(styles.statistics_group_header, 'custom-font')}>
  {label}
</h3>
}